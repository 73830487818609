import { InvolveCards } from "../Components/InvolveCards";



export default function getInvolved() {
  return (
    <div className="bg-white ">
      <header className="relative">
        <img
          alt="Get Involved"
          className="w-full h-[400px] object-cover"
          height="400"
          src="/images/Balls.jpeg"
          style={{
            aspectRatio: "988/400",
            objectFit: "cover",
          }}
          width="988"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center">
          <h2 className="text-6xl font-bold text-white rounded-md bg-[#f97316] px-8 py-4">Get Involved</h2>
        </div>
      </header>
      <section className="container mx-auto py-4 px-4">
        <div className=" ">
            <InvolveCards />
        
          {/* <Card className="bg-[#3b82f6] text-white">
            <CardHeader>
              <CardTitle>Donate Equipment</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                One of Helping Wheel's primary missions is to provide sports equipment to students in need. This may
                include specific equipment for a sport or clothing items, such as shoes. If you have items of this
                nature that are no longer used, and the items are in good condition, we would gladly accept these items
                as a donation. This can be done through contacting our email helpingwheel@example.com.
              </p>
            </CardContent>
          </Card> */}
          {/* <Card className="bg-[#22c55e] text-white">
            <CardHeader>
              <CardTitle>Donate Funds</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                If you would like to donate monetarily, we urge you to do so. Your gift can be as small or large as you
                choose. Every contribution makes a difference in a student's life.
              </p>
            </CardContent>
          </Card> */}
          {/* <Card className="bg-[#f97316] text-white">
            <CardHeader>
              <CardTitle>Volunteer</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                Since this organization is run by a team of two, we are always looking for volunteers to help us expand
                and improve upon our fundraising efforts. Helping Wheel is looking for a wide variety of people that
                offer different skill sets. If you feel as though you have something to offer, we encourage you to apply
                through filling out the application sheet by clicking the blue VOLUNTEER button above.
              </p>
            </CardContent>
          </Card> */}
        </div>
      </section>
    </div>
  )
}
