import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";

export const HoverImageLinks = () => {
  return (
    <section className="">
      <div className=" max-w-5xl">
        <Link
          heading="English Application: "
          subheading="For English speakers."
          href="https://docs.google.com/forms/d/e/1FAIpQLSfAA_w3OMKZ3ZXjPXf97vlOfie-kWox7WKED3COMtXS4rK2dg/viewform?usp=sf_link"
        />
        <Link
          heading="Spanish Application: "
          subheading="For Spanish speakers."
          href="https://docs.google.com/forms/d/e/1FAIpQLSfoxtbCgECJuJGl_7HPpYcW9lKTKs7_We_wV8xwLQC6e-YQcg/viewform?usp=sf_link"
        />
        <Link
          heading="Organization Application:"
          subheading="For organizations that would like to partner with us."
          href="https://docs.google.com/forms/d/e/1FAIpQLSfRV_qOmvixGLjGU5Xms59QWA9gdAKhAvtcWh9eOUTw7TDEuQ/viewform?usp=sf_link"
        />
      </div>
    </section>
  );
};
interface LinkProps {
    heading: string;
    subheading: string;
    href: string;
  }

const Link = ({ heading, subheading, href }: LinkProps) => {
    const ref = useRef<HTMLAnchorElement | null>(null);
  
    const x = useMotionValue(0);
    const y = useMotionValue(0);
  
    const mouseXSpring = useSpring(x);
    const mouseYSpring = useSpring(y);
  
    const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
    const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);
  const handleMouseMove = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const rect = ref.current!.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };

  return (
    <motion.a
      href={href}
      ref={ref}
      target="_blank"
      onMouseMove={handleMouseMove}
      initial="initial"
      whileHover="whileHover"
      className="group relative flex items-center justify-between border-b-2 border-gray-500 transition-colors duration-500 hover:border-black"
    >
      <div>
        <motion.span
          variants={{
            initial: { x: 0 },
            whileHover: { x: -16 },
          }}
          transition={{
            type: "spring",
            staggerChildren: 0.075,
            delayChildren: 0.25,
          }}
          className="relative z-10 block text-xl text-gray-500 transition-colors duration-500 group-hover:text-black md:text-2xl"
        >
          {heading.split("\n").map((l, i) => (
            <motion.span
              variants={{
                initial: { x: 0 },
                whileHover: { x: 16 },
              }}
              transition={{ type: "spring" }}
              className="inline-block"
              key={i}
            >
              {l}
            </motion.span>
          ))}
        </motion.span>
      </div>

      <motion.div
        variants={{
          initial: {
            x: "25%",
            opacity: 0,
          },
          whileHover: {
            x: "0%",
            opacity: 1,
          },
        }}
        transition={{ type: "spring" }}
        className="relative z-10 p-4"
      >
        <FiArrowRight className="text-5xl text-black" />
      </motion.div>
    </motion.a>
  );
};

export default HoverImageLinks;