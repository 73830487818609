import { MouseEventHandler } from "react"

const people = [
  {
    name: 'Donate',
    description: 'Helping Wheel is a new nonprofit that  relies on donations from kind individuals in order to operate. Please consider donating to our organization.',
    imageUrl:
      'images/donate.jpeg',
    onclick: "/donate"
  },
  {
    name: 'Receive Help',
    description: 'We offer many different services to help students in need.  If you are a student in need and if you reside in the region that we serve, please consider reaching out to us.',
    imageUrl:
        'images/recieve.jpeg',
    onclick: "/receive-help"
  },
  {
    name: 'Volunteer',
    description: 'We are always looking for new volunteers to help us on our journey.  We want to help every student imaginable and offer many different volunteering services that fit every skill level.',
    imageUrl:
        'images/involved.jpeg',
    onclick: "/get-involved"
  }

]

export default function Cards() {
  return (
    <ul role="list" className="flex flex-col sm:flex-row md:flex-row flex-center  gap-12 justify-center mx-auto">
      {people.map((person) => (
        <li
          key={person.name}
          className="col-span-1 flex basis-1/3 flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
        >
        <div className="flex flex-1 flex-col p-8">
            <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full pb-2" src={person.imageUrl} alt="" />
            <button onClick={() => {window.location.href = person.onclick;}} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    <h3 className="text-sm font-medium text-gray-900">{person.name}</h3>
            </button>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dd className="text-sm text-gray-500">{person.description}</dd>
                <dd className="mt-3">
                </dd>
            </dl>
        </div>
          <div>
          </div>
        </li>
      ))}
    </ul>
  )
}
