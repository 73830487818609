import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"
import { GalleryWithCarousel } from "../Components/ImageCorusel"
import ContactForm from "../Components/ContactForm"



export default function AboutUs() {
    return (
      <div className="bg-[#e6ecf8] p-10 max-w-6xl mx-auto my-12 rounded-lg">
        
        <h1 className="text-4xl font-bold text-center mb-6">About Us</h1>
        <div className="relative">
          {/* <img
            alt="Team"
            className="w-full h-auto rounded-lg mb-6"
            height="400"
            src="/placeholder.svg"
            style={{
              aspectRatio: "800/400",
              objectFit: "cover",
            }}
            width="800"
          /> */}
          <GalleryWithCarousel />
        </div>
        <div className="text-lg">
          <p className="mb-4">
            <span className="text-3xl font-bold">H</span>
            elping Wheel is a family-operated nonprofit that started in the Fall of 2021. In my freshman year of high school, I met a parent at a sports event that stated how much they would love for their child to participate in sports, but they were unable to pay the associated costs. Following this interaction, I had many more conversations with other people that were in the same boat.  I also learned of stories about individuals who helped those in need.  My history teacher described how he would pay for his student's AP exams because they were unable to afford them themselves. This led me to understand the extent to which sports and academic funding was an issue. These conversations never left me as I traversed through high school. During Covid, I began to do much research on this issue and realized that there were very few resources available to address this problem. Because of this, I decided to take a stand. During the beginning of my Junior year, my dad and I started this nonprofit to provide aid to students in need. We have been fortunate to receive a significant amount of community support throughout this process and are very excited for what the future holds for Helping Wheel.
          </p>
          {/* <p className="text-sm text-gray-600 italic">Screenshot from Mailchimp, January 2022</p> */}
        </div>
        <div style={{height: '2px', width: '100%', backgroundColor: 'black'}} />
        <ContactForm />
      </div>
    )
  }
  
//   function ShareIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
//     return (
//       <svg
//         {...props}
//         xmlns="http://www.w3.org/2000/svg"
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="currentColor"
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       >
//         <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
//         <polyline points="16 6 12 2 8 6" />
//         <line x1="12" x2="12" y1="2" y2="15" />
//       </svg>
//     )
//   }
  