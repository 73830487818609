import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar'
import Home from './Pages/home';
import RecieveHelp from './Pages/RecieveHelp';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from './Components/Footer';
import GetInvolved from './Pages/getInvolved';
import AboutUs from './Pages/AboutUs';
import DonateUs from './Pages/DonateUs';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const initialOptions = {
  clientId: "test",
  currency: "USD",
  intent: "capture",
};

function App() {
  return (
    <div className='flex flex-col h-screen'>
    <BrowserRouter>
      <Navbar />
      <PayPalScriptProvider deferLoading={true} options={initialOptions}>
            <PayPalButtons style={{ layout: "horizontal" }} />
      </PayPalScriptProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path='/receive-help' element={<RecieveHelp />} />
        <Route path= '/get-involved' element={<GetInvolved />} />
        <Route path= '/donate' element={<DonateUs />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
