
// import {useState } from 'react'


// const dropdownOptions = [
//     { id: 1, name: 'Myself' },
//     { id: 2, name: 'An Organization' },
//     { id: 3, name: 'Someone else' },
// ]



export default function DonateMoney() {
    // const [selected, setSelected] = useState(dropdownOptions[0])
    return (
        <div className="max-w-4xl mx-auto p-8 bg-[#f97316] rounded-lg">
            <h1 className="text-6xl font-bold text-center text-white mb-4">Support Our Cause</h1>
            <h2 className="text-2xl font-semibold text-center text-white mb-8">Leave a One-Time Donation</h2>
            <form className="grid grid-cols-1 md:grid-cols-3 gap-6">

                <div className="flex items-center justify-center col-span-3">
          <button className="rounded-md bg-white h-14 w-28 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-orange-100"
          onClick={() =>{
            // Specify the URL you want to open
            const url = 'https://www.paypal.com/donate/?hosted_button_id=YLAQ7KXG2N8AL';
            
            // Open a new window
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
          }} style={{cursor: 'pointer'}}
          >Donate</button>
        </div>

            </form>

        </div>
    )
}















                {/* <div>
                    <label htmlFor="name" className="ml-px block pl-4 text-sm font-medium leading-6 text-gray-900">
                        First Name
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Jane"
                        />
                    </div>
                </div> */}
                {/* <div>
                    <label htmlFor="name" className="ml-px block pl-4 text-sm font-medium leading-6 text-gray-900">
                        Last Name
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Smith"
                        />
                    </div>
                </div> */}
                {/* <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="you@example.com"
                        />
                    </div>
                </div> */}
                {/* <div>
                    <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Donate in the name of:</Listbox.Label>
                                <div className="relative mt-2">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selected.name}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {dropdownOptions.map((option) => (
                                                <Listbox.Option
                                                    key={option.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-8 pr-4'
                                                        )
                                                    }
                                                    value={option}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                {option.name}
                                                            </span>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div> */}
                {/* <div className="flex flex-col col-span-2 md:col-span-1">
                    <label className="mb-2 font-medium" htmlFor="hearAboutUs">
                        How did you hear about us?
                    </label>
                    <input
                        type="text"
                        name="hearAboutUs"
                        id="hearAboutUs"
                        className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Source"
                    />
                </div> */}
                {/* <div className="flex flex-col col-span-2">
                    <label className="mb-2 font-medium" htmlFor="amount">
                        Enter the amount you wish to pay: *
                    </label>

                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                            type="text"
                            name="price"
                            id="price"
                            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                            </span>
                        </div>
                    </div>

                </div> */}