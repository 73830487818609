import React from 'react';
import DoAndDont from '../Components/DoAndDont';
import { List, ListItem, Card } from "@material-tailwind/react";
import HoverImageLinks from '../Components/ReceiveLinks';


export default function RecieveHelp() {
    return (
        <div className="bg-white ">
            <header className="relative">
                <img
                alt="Receive Help"
                className="w-full h-[400px] object-cover"
                height="400"
                src="/images/receiveHelp.jpeg"  
                style={{
                    aspectRatio: "988/400",
                    objectFit: "cover",
                }}
                width="988"
                />
                <div className="absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center">
                <h2 className="text-6xl font-bold text-white rounded-md bg-[#f97316] px-8 py-4">Receive Help</h2>

                </div>
            </header>
      <div className="container space-y-6 m-auto p-4 md:p-8  py-10 sm:py-32 lg:px-8">
      <h2 className="mt-3 mb-3 text-2xl leading-8 text-black">
                        Please read before submitting an application.
                    </h2>
        <div className="space-y-4">
          <p className="text-gray-500">
            As Helping Wheel continues to grow and expand into new counties, there has been some confusion regarding the
            types of financial aid we provide. Please note that due to limited funding, we can only offer assistance for
            specific activities. If your activity falls within the criteria outlined below, you are eligible to apply.
            If your activity is not listed and you are unsure whether you qualify, please don't hesitate to reach out to
            us via email at
            <a href="#">helpingwheelva@gmail.com</a>.
          </p>
          <DoAndDont />
          <p className="text-gray-500">
          If you need our services, please click and select the appropriate option below and fill out the form.
            </p>
        <HoverImageLinks />
        <p className="text-gray-500">
        Someone will be in contact with you regarding the application soon after submission. Please expect to have email communication with us for at least a few days after submission. We understand that everyone may not be able to communicate through email. If this is true, please specify in the application and someone will reach out through phone call/text. If you do not communicate with us after the application is submitted, we are not able to help you. This application may be filled out by the student, parent, or school counselor. If you do not have access to the internet at your home, this application can be filled out through public internet such as at a school or library. If you are still having issues, please contact your school's counseling office. Someone will be in contact with you shortly regarding this issue. 
        </p>
           
        </div>
      </div>
      </div>
    )
}