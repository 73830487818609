import {
    AcademicCapIcon,
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
    XMarkIcon,
  } from '@heroicons/react/24/outline'
  
  const actions = [
    {
      title: 'What we Cover',
      href: '#',
      icon: CheckBadgeIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
      description: ["A sports team provided by the school within the county we partner with",

      "A club fee that is through your school",
      
      "A field trip that is through a club you are in ",
      
      "AP testing ",
      
      "GED testing for students who are still in school i.e.: 5th-year seniors, who would like to take the test instead of receiving a high school diploma "
      ]
    },
    {
      title: 'What we do not cover',
      href: '#',
      icon: XMarkIcon,
      iconForeground: 'text-red-700',
      iconBackground: 'bg-red-50',
      description: [
        "Sports teams that are through a private entity i.e. the YMCA",

       "Field trips that are not through a club",
        
        "Duel Enrollment ",
        
        "SAT/ACT testing",
        
        "Prom/homecoming tickets ",
        
        "College application fees"
      ]
    },
  ]
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function DoAndDont() {
    return (
      <div className="divide-y overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'flex-row inline-flex rounded-lg p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />

              <h3 className="pl-3 text-base font-semibold leading-6 text-gray-900">
                <a href={action.href} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              </span>

            </div>
            <div className="mt-8">

                {action.description.map((description, descriptionIdx) => (
                  <li key={descriptionIdx} className="text-gray-500">
                    {description}
                  </li>
                ))}

            </div>

          </div>
        ))}
      </div>
    )
  }
  