import DonateMoney from "../Components/DonateMoney";


export default function DonateUs() {
    return (
        <div className="bg-white">
        <header className="relative">
            <img
            alt="DONATE US"
            className="w-full h-[400px] object-cover"
            height="400"
            src="/images/donateWallpaper.jpeg"
            style={{
                aspectRatio: "988/400",
                objectFit: "cover",
            }}
            width="988"
            />
            <div className="absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center">
                <h2 className="text-6xl font-bold text-white rounded-md bg-[#f97316] px-8 py-4">Support Us</h2>
            </div>
        </header>
        <div className="container space-y-6 m-auto p-4 md:p-8  py-10 sm:py-32 lg:px-8">
            <p className="text-gray-500">
            Helping Wheel is a new nonprofit that was created in 2021. Because of this, we have little funds and are only able to help a select number of students. In order to operate, we rely on grants, donations from other organizations, and donations from kind individuals like you.  Our funds are dispersed in a multitude of ways, including the purchasing of new and used sports equipment from third-party sellers or payment for a sport, club, or AP exam directly to the school the student attends. Currently, we operate within Spotsylvania County, The City of Fredericksburg School, and the Caroline County School System. Due to funding, we hypothesize that Helping Wheel will be focusing on these counties for the foreseeable future, 
            </p>
            <p className="text-gray-500">
            With the growth of this nonprofit, we hope to expand into surrounding regions, potentially Orange County, Stafford County, and King George County. This vision will be able to be reached as the generous donations from individuals and local organizations increase. Due to recent events, it is understandable that many of you are unable to donate at this time. We ask you to check out our social media pages and share the mission of Helping Wheel with others who have not heard about us. Spreading the message is just as important as providing a donation. Thank you for all you have done.  Your help truly means a lot. 
            </p>
            <DonateMoney />
         </div>
        </div>
    )
}
