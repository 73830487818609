import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";
   
const data = [
    {
        title: "Donate Equipment",
        description: "One of Helping Wheel's primary missions is to provide sports equipment to students in need. This may include specific equipment for a sport or clothing items, such as shoes. If you have items of this nature that are no longer used, and the items are in good condition, we would gladly accept these items as a donation. This can be done through contacting our email below. Someone will be in contact with you in regards to how to drop the item(s) off. ",
        cfa: "helpingwheelva@gmail.com",
        href: "mailto:helpingwheelva@gmail.com"
    },
    {
        title: "Donate Funds",
        description: "If you would like to donate monetarily, we urge you to do so. Your gift can be as small or large as you choose. Every contribution makes a difference in a student's life.",
        cfa: "Donate here",
        href: "/donate"
    },
    {
        title: "Volunteer",
        description: "Since this organization is run by a team of two, we are always looking for volunteers to help us expand and improve upon our fundraising efforts.  Helping Wheel is looking for a wide variety of people that offer different skill sets. If you feel as though you have something to offer, we encourage you to apply through filling out the application form below.",
        cfa: "Apply here",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSckLPvd7fJheuBVdJALwmfY8QJP5CfoYJ0qyPAs4lbmAtY47w/viewform?usp=sf_link"
    }
]



export function InvolveCards() {
    return (
        <div>
            <div className="w-full flex flex-col md:flex-row justify-center">
                {data.map((item) => (
                    <Cards title={item.title} description={item.description} cfa={item.cfa} href={item.href} />
                ))}
            </div>
        </div>
    )

}

interface LinkProps {
    title: string;
    description: string;
    cfa: string | null;
    href: string;
  
}

  
const Cards= ({title, description, cfa, href} : LinkProps) => {
    return (
        <Card className="mt-6 w-96 " placeholder={undefined}>
            <CardBody  placeholder={undefined}>
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="mb-4 h-12 w-12 text-gray-900"
                >
                    <path
                        fillRule="evenodd"
                        d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
                        clipRule="evenodd"
                    />
                    <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
                </svg> */}
                <Typography variant="h5" color="blue-gray" className="mb-2" placeholder={undefined}>
                    {title}
                </Typography>
                <Typography  placeholder={undefined}>
                    {description}
                </Typography>
            </CardBody>
            {cfa != null && <CardFooter className="pt-0" placeholder={undefined}>
                <a href={href} target="__None" className="inline-block">
                    <Button size="sm" variant="text" className="flex items-center gap-2" placeholder={undefined}>
                        {cfa}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                        </svg>
                    </Button>
                </a>
            </CardFooter>}
        </Card>
    );
}
