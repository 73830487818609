import { Carousel } from "@material-tailwind/react";
 
export function GalleryWithCarousel() {
  return (
    <Carousel loop={true} autoplay={true} className="rounded-xl overflow-hidden" placeholder="">
      <img
        src="images/banner.jpeg"
        alt="image 1"
        className="h-full w-full object-cover object-center"
      />
      <img
        src="images/awarded.jpeg"
        alt="image 2"
        className="h-full w-full object-cover object-center"
      />
      <img
        src="images/awarded2.jpeg"
        alt="image 3"
        className="h-full w-full object-cover object-center"
      />
      <img
            src="images/tournament_Sponsor.jpeg"
            alt="image 4"
            className="h-full w-full object-cover object-center"
        />
    </Carousel>
  );
  }
